// vars
$board-width: 60vh;
$border-width: 30px;
$border-color: burlywood;

.board {
  position: relative;
  padding-bottom: calc(100% - #{$border-width} * 2);
  border: $border-width outset $border-color;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.grid {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  height: 100%;
}

.position {
  position: absolute;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: bold;

  &.files {
    top: 100%;
    left: 0;
    grid-template-columns: repeat(8, 1fr);
    width: 100%;
    height: $border-width;
  }

  &.ranks {
    top: 0;
    left: 100%;
    grid-template-rows: repeat(8, 1fr);
    width: $border-width;
    height: 100%;
  }
}
