* {
  box-sizing: border-box;

  :focus {
    outline: none;
  }
}

body {
  margin: 0;
  background-color: darkslategray;
  color: burlywood;
  font-family: georgia, serif;
  font-size: 0.9em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
