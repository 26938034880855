.piece {
  width: calc(100% / 8);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 90%;
  transition: all 0.3s;

  .board & {
    position: absolute;
    height: calc(100% / 8);
    pointer-events: none;
    z-index: 2;
  }

  &.black {
    &.rook {
      background-image: url(/assets/pieces/bR.svg);
    }
    &.knight {
      background-image: url(/assets/pieces/bN.svg);
    }
    &.bishop {
      background-image: url(/assets/pieces/bB.svg);
    }
    &.queen {
      background-image: url(/assets/pieces/bQ.svg);
    }
    &.king {
      background-image: url(/assets/pieces/bK.svg);
    }
    &.pawn {
      background-image: url(/assets/pieces/bP.svg);
    }
  }

  &.white {
    &.rook {
      background-image: url(/assets/pieces/wR.svg);
    }
    &.knight {
      background-image: url(/assets/pieces/wN.svg);
    }
    &.bishop {
      background-image: url(/assets/pieces/wB.svg);
    }
    &.queen {
      background-image: url(/assets/pieces/wQ.svg);
    }
    &.king {
      background-image: url(/assets/pieces/wK.svg);
    }
    &.pawn {
      background-image: url(/assets/pieces/wP.svg);
    }
  }

  &.active {
    z-index: 3;
  }

  .captured & {
    height: 100%;
    padding: 0 30px;
    animation-name: bounce;
    animation-duration: 0.3s;
  }
}

@keyframes bounce {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
